<script>
  /**
   * A modal displaying an error message, optionally with calls to action.
   */
  export default {
    props: {
      /** Whether the modal is currently open (visible). */
      open: {
        type: Boolean,
        default: false,
      },
      /** What the icon color should be. */
      iconColor: {
        type: String,
        default: 'green',
        validator: (value) => ['green', 'primary-dark', 'white', 'summer'].includes(value),
      },
      /** What the icon should be. */
      icon: {
        type: String,
        default: 'IconInfo',
        validator: (value) => ['IconInfo', 'IconInfoAlert'].includes(value),
      },
      /** Whether `AppHeader` should be hidden on mobile. */
      hideNavOnMobile: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      dismiss() {
        /** Emitted when the modal is dismissed. */
        this.$emit('dismiss')
      },
    },
  }
</script>

<template>
  <BaseModal
    v-if="open"
    :open="open"
    :hide-nav-on-mobile="hideNavOnMobile"
    size="xs"
    dismissible
    @dismiss="dismiss"
  >
    <div class="flex items-center -ml-1">
      <BaseIconInput :color="iconColor">
        <component :is="icon" />
      </BaseIconInput>
      <span class="font-semibold" data-cy="error-modal-header">
        <!-- @slot Optional. The modal’s header/title. Should contain text. Defaults to “Something went wrong”. -->
        <slot name="header">Something went wrong</slot>
      </span>
    </div>
    <div class="mt-2 text-sm">
      <!-- @slot The modal’s content (likely an error message). Can contain anything. -->
      <slot />
    </div>
    <div v-if="$scopedSlots.cta">
      <BaseDivider class="my-6" />
      <div class="flex flex-col items-center">
        <div v-if="$scopedSlots.actionTitle" class="text-lg font-semibold mb-4 text-center">
          <!-- @slot Optional. Only if the `cta` slot is used. A heading above the call to action. Should contain text. -->
          <slot name="actionTitle" />
        </div>
        <div class="w-70">
          <!-- @slot Optional. Call to action. Should contain text. -->
          <slot name="cta" />
        </div>
        <!-- @slot Optional. Only if the `cta` slot is used. A link below the call to action. Should contain a `BaseLinkStyled` component. -->
        <div v-if="$scopedSlots.secondCtaLink" class="mt-4">
          <slot name="secondCtaLink" />
        </div>
      </div>
    </div>
  </BaseModal>
</template>
